import { createApp } from 'vue'
import App from './App.vue'
import 'font-awesome/css/font-awesome.min.css';
import posthogPlugin from "./../plugins/posthog"; //import the plugin. 


// createApp(App).mount('#app').use(posthogPlugin); //install the plugin

const app = createApp(App);
app.use(posthogPlugin);
app.mount('#app');