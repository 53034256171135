<template>
  <div id="app">
    <div class="container">
      <h1>Welcome to KickStartGit 💥</h1>
      <h3>Let's turbocharge your Github activity. 🚀</h3>
      <h3>⌛🧘‍♀️ Please be patient after authenticating as it usually takes the app ~15 seconds to generate your activity.</h3>
      
      
      <div class="video-container">
        <video width="200" height="200" autoplay loop muted playsinline>
          <source src="https://kst-website-assets.s3.eu-west-2.amazonaws.com/git_white_animation.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      
      <div v-if="!user">
        <h1> 📌 How to use KickStartGit</h1>
        <h2>1. Get a Personal Access Token from GitHub</h2>
        <div class="content">
          <ol>
            <li>Click the button below to visit GitHub.com in a new window.</li>
            <li>Scroll to the bottom and click <b>Get my Token!</b></li>
            <li>Copy the generated token and paste it below.</li>
          </ol>

          <a :href="tokenUrl" 
          target="_blank" class="btn btn-flashing-green btn-green-text" :class="{ 'btn-loading': loadingToken }"> > Get my Token!</a>
          
        </div>

        <h2>2. Paste Token and click Submit to generate Github Activity.</h2>

        <div class="input-btn-container">
            <input v-model="githubToken" placeholder="Paste your GitHub token here" class="token-input" />
            <button @click="logToken" class="btn btn-flashing-gray-black" :class="{ 'btn-loading': loadingToken }">
            <i class="fa fa-github"></i> {{ loadingToken ? 'Loading...' : 'Submit Token' }}
          </button>
        </div>

      </div>

      <div class="spinner-container" v-if="isLoading && !user">
        <flower-spinner :animation-duration="2500" :size="70" color="#ff6868" :loading="isLoading"></flower-spinner>
      </div>

      <div v-if="user" class="user-section">
    <div class="user-info">
      <h1>Welcome {{ user.login }} 👋</h1>
      <img :src=user.avatar_url alt="User Avatar" class="avatar"/>

      <div class="content" style="text-align: left;margin-top: 40px;">
          
        <h3>🪄 This is what we have done so far..</h3>
        <ol>
            <li>🎉 We have just created a lot of activity in your account to boost your profile.</li>
            <li>⏰ Remember to Enable Private Contributions</li>
            <li>💰 <strong><a href="https://buy.stripe.com/8wM28pacg7iLbIsaEP" target="_blank">Subscribe</a> to KickStartGit for 0.99$/month and <u>we'll keep your profile updated!</u></strong></li>


          </ol>
          
        </div>

        <div class="content" style="text-align: left;margin-top: 40px;" >
          <h3>🛠 How to enable Private Contributions on your profile</h3>
          <ol>
            <li>Go to your Github profile using the following <a :href="user.html_url" target="_blank">[Link]</a>.</li>
            <li>Click "Contribution settings" at the top-right corner of your activity chart.</li>
            <li>Click "Enable Private Contributions".</li>
          </ol>
          
        </div>
    </div>

    


    

    <div class="gif-container">
      <img src="./assets/output_contributions.gif" alt="Enable Private Contributions GIF" class="responsive-image" />
    </div>
    <h3>📊 Your new profile should look like this now 👇</h3>
    <div class="image-container">
      <img src="./assets/git_activity.png" alt="Git Activity Template" class="responsive-image" />
    </div>
  </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import { FlowerSpinner } from "epic-spinners";
import posthog from "posthog-js";

var globalUserPlan = null;


export default {
  name: "App",
  components: {
    FlowerSpinner
  },
  data() {
    return {
      user: null, //comment out for testing

      // user: {
      //   login: 'dummyuser',
      //   id: 1234567,
      //   node_id: 'MDQ6VXNlcjEyMzQ1Njc=',
      //   avatar_url: 'https://avatars.githubusercontent.com/u/1234567?v=4',
      //   gravatar_id: '',
      //   url: 'https://api.github.com/users/dummyuser',
      //   html_url: 'https://github.com/dummyuser',
      //   followers_url: 'https://api.github.com/users/dummyuser/followers',
      //   following_url: 'https://api.github.com/users/dummyuser/following{/other_user}',
      //   gists_url: 'https://api.github.com/users/dummyuser/gists{/gist_id}',
      //   starred_url: 'https://api.github.com/users/dummyuser/starred{/owner}{/repo}',
      //   subscriptions_url: 'https://api.github.com/users/dummyuser/subscriptions',
      //   organizations_url: 'https://api.github.com/users/dummyuser/orgs',
      //   repos_url: 'https://api.github.com/users/dummyuser/repos',
      //   events_url: 'https://api.github.com/users/dummyuser/events{/privacy}',
      //   received_events_url: 'https://api.github.com/users/dummyuser/received_events',
      //   type: 'User',
      //   site_admin: false,
      //   name: 'Dummy User',
      //   company: 'Dummy Company',
      //   blog: 'https://dummyuser.com',
      //   location: 'Dummy Location',
      //   email: 'dummyuser@example.com',
      //   hireable: true,
      //   bio: 'asd',
      //   twitter_username: 'dummyuser',
      //   public_repos: 42,
      //   public_gists: 17,
      //   followers: 108,
      //   following: 150,
      //   created_at: '2020-01-01T00:00:01Z',
      //   updated_at: '2023-01-01T00:00:01Z'
      // },

      isLoading: false,
      githubToken: '',
      loadingToken: false,
      userPlan: 'free' 
    };
  },
  created() {
    posthog.capture('$pageview', { page: 'App Front End loaded', description: 'User loaded App front-end.'});
    console.log("PostHog $pageview event fired.");
    this.isLoading = false;

    // Capture the user plan from URL parameter
    const urlParams = new URLSearchParams(window.location.search);
    this.userPlan = urlParams.get('plan') || 'free'; // Default to 'free' if no plan is specified

    globalUserPlan = this.userPlan;
    console.log("User Plan:", this.userPlan); // Logging within the component

  },
  computed: {
    tokenUrl() {
      const scientists = [
        'Linus Torvalds', 'Alan Turing', 'Ada Lovelace', 'Tim Berners-Lee', 
        'Grace Hopper', 'Richard Stallman', 'Ken Thompson', 'Donald Knuth', 
        'Andrew Tanenbaum', 'Robert Tarjan', 'James Gosling', 'Dennis Ritchie', 
        'Bjarne Stroustrup', 'John McCarthy', 'Edsger W. Dijkstra', 
        'Claude Shannon', 'Niklaus Wirth', 'Jean Bartik', 'Steve Wozniak', 
        'Michael Stonebraker', 'Margaret Hamilton'
      ];
      const randomScientist = scientists[Math.floor(Math.random() * scientists.length)];
      const description = encodeURIComponent(`${randomScientist} Token`);
      return `https://github.com/settings/tokens/new?scopes=repo,admin:org,write:packages,read:packages,delete:packages,admin:repo_hook,admin:org_hook,gist,notifications,user,delete_repo,write:discussion,read:discussion,write:public_key,read:public_key,admin:gpg_key&description=${description}`;
    }
  },


  methods: {
    logToken() {
      this.loadingToken = true;
      this.isLoading = true;
      
      console.log('GitHub Token:', this.githubToken);

      // const URL = 'http://preprod-docker-app.eba-4pq3n3hw.eu-west-2.elasticbeanstalk.com:3333/decode'; pre HTTPS
      
      const URL = 'https://api.kickstartgit.com/decode'; // with HTTPS
      const payload = {
        accessToken: this.githubToken,
        userPlan: globalUserPlan // Add the plan to the data sent via POST
      };

      // Log the payload
      console.log('Sending payload to server:', payload);

      axios.post(URL, payload)
        .then(response => {
          console.log('Response from server:', response.data);
          this.user = response.data;
        })
        .catch(error => {
          console.error('Error sending the token:', error.response ? error.response.data : error.message);
        });
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5; /* Light grey background */
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.container {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
  padding: 20px;
  max-width: 800px;
  width: 100%;
  text-align: left;
  margin: 20px;
}

.description {
  margin: 0 0 20px;
}

.content, .input-btn-container, .spinner-container, .gif-container, .image-container {
  margin: 0 0 20px;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    color: #fff;
    border: none;
    padding: 8px 15px;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-family: inherit;
    font-weight: bold;
    margin-top: 20px;
    width: 200px;
    height: 34px; /* This matches the height of the input */
    text-align: center; /* Ensures text is centered horizontally */
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

h1, h2, h3 {
  margin-bottom: 30px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: -60px;
}

.gif-container, .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-input {
  width: 200px;  
  padding: 8px 15px;
  border-radius: 10px;
  border: 1px solid grey;
  margin-top: 20px;
  font-size: 15px;
  height: 35px; /* Match the height of the button */
  box-sizing: border-box; /* Ensures padding and border are included in the total height */
  line-height: 15px;
  vertical-align: middle; /* This can help with some alignment issues in certain scenarios */
}

.empty-space {
  margin-bottom: 20px;
}

.avatar {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 20px 0;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-loading {
  background-color: grey;
  cursor: not-allowed;
}

.btn i.fa {
  margin-right: 10px;
  font-size: 20px;
}

/* Flexbox styles for input and button alignment */
.input-button-wrapper {
  display: flex;
  align-items: center; /* Center items vertically */
  gap: 20px;
  justify-content: center;
}

.input-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.token-input, .btn {
    box-sizing: border-box;
    height: 40px;
}

.token-input {
    width: 400px;  
    padding: 8px 15px;
    border-radius: 10px;
    border: 1px solid grey;
    margin-top: 20px;
    font-size: 15px;
}

.btn {
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-family: inherit;
    font-weight: bold;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    width: 200px;
}

ol {
    list-style-type: decimal !important; /* ensures that the list is numbered */
    margin: 0 0 0 2em; /* 2em left margin to ensure numbers are visible */
    padding: 0;
}

li {
    margin: 0 0 10px; /* Adds a little space below each list item */
}

.user-section {
  background: #f9f9f9; 
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-info {
  text-align: center;
  margin-bottom: 20px;
}

.gif-container, .image-container {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}


/* Keyframes for flashing background animation */
@keyframes flashGreen {
  0%, 100% { background-color: #000000; } /* Original green color */
  50% { background-color: #34d058; } /* Lighter green color */
}

/* Applying the animation to the button */
.btn-flashing-green {
  animation: flashGreen 3s infinite;
}

@keyframes flashGrayBlack {
  0%, 100% { background-color: #333; } /* Dark gray, close to black */
  50% { background-color: #bbb; } /* Light gray color */
}

/* Applying the animation to the 'Submit Token' button */
.btn-flashing-gray-black {
  animation: flashGrayBlack 3s infinite;
  color: #fff; /* Keeping text color white for better contrast */
}

.btn-flashing-green:hover {
  animation-play-state: paused; /* Stops the animation on hover */
  background-color: #4CAF50; /* Set the solid green color on hover */
  /* color: #000; Text color black */
}


/* Hover state for 'Submit Token' button */
.btn-flashing-gray-black:hover {
  animation-play-state: paused; /* Stops the animation on hover */
  background-color: #333; /* Set the solid dark gray/black color on hover */
  color: #fff; /* Text color white */
}

</style>
