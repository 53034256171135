//./plugins/posthog.js
import posthog from "posthog-js";

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(
      "phc_oyOlYSO0OhrmGOb64q38Nj9U5Us74Y5AKlfnOjYcZcS",
      {
        api_host: "https://eu.posthog.com",
      }
    );
  },
};